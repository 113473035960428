import ReferenceCard from '../common/ReferenceCard';
import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import { Container, Section } from '@components/global';

const referenceData = [
	{
		text: [
			'Francesc has been volunteering for the nonprofit I direct. In that time, he’s become one of our most senior and respected developers… and we have had over 50 volunteer developers participate in that time. Francesc is quick to assist others, take on larger tasks, review code by other volunteers, and troubleshoot solutions. Many volunteers come and go — but Francesc is recognized as a leader in the group and we’re very lucky to have him involved.',
		],
		author: 'Ben Smilowitz',
		companies: ['Disaster Accountability Project'],
		companyLinks: ['http://disasteraccountability.org/'],
		companyLogos: ['/logos/dap_logo_white.png'],
		position: 'CEO',
		picture: '/pictures/bensmilowitz.png',
		linkedInLink: 'https://www.linkedin.com/in/bensmilowitz/',
	},
	{
		text: [
			"I had the privilege of working with Francesc within Framework development team at Barclays. Francesc is proactive, result oriented, responsible and technically skilled employee and he is always ready to put all his energy and time to get the job done. He has an exceptional troubleshooting and analytical skill to resolve any problem. Francesc is a great asset to any company and team and I wouldn't hesitate to work with him again.",
		],
		author: 'Pavel Jakl',
		companies: ['Barclays'],
		companyLinks: ['https://www.investmentbank.barclays.com/', 'http://accolade.com/'],
		companyLogos: ['/logos/barclays_logo_white.svg'],
		position: 'Former VP',
		picture: '/pictures/paveljakl.png',
		linkedInLink: 'https://www.linkedin.com/in/pjakl/',
	},
];

const References = () => (
	<StaticQuery
		query={graphql`
			query {
				art_story: file(sourceInstanceName: { eq: "art" }, name: { eq: "tell_story" }) {
					childImageSharp {
						fluid(maxWidth: 1200) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
			}
		`}
		render={() => (
			<Section id="references">
				<Container>
					<div>
						<div className="pb-16 md:pb-20">
							<div className="relative">
								<div className="absolute inset-0 flex items-center" aria-hidden="true">
									<div className="w-full border-t border-gray-300" />
								</div>
								<div className="relative flex justify-center">
									<h2 className="px-4 bg-white">References</h2>
								</div>
							</div>
						</div>
						<CardFlex>
							{referenceData.map((r) => (
								<ReferenceCard
									key={r.author}
									text={r.text}
									author={r.author}
									companies={r.companies}
									position={r.position}
									picture={r.picture}
									companyLinks={r.companyLinks}
									companyLogos={r.companyLogos}
									linkedInLink={r.linkedInLink}
								/>
							))}
						</CardFlex>
					</div>
				</Container>
			</Section>
		)}
	/>
);

const CardFlex = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
`;

export default References;

import React from 'react';

import { Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

import styled from 'styled-components';

const SOCIAL = [
	{
		icon: '/icons/whatsapp.svg',
		link: 'https://api.whatsapp.com/send?phone=420608095964',
	},
	{
		icon: '/icons/linkedin.svg',
		link: 'https://www.linkedin.com/in/fmontserrat/',
	},
	{
		icon: 'icons/github.svg',
		link: 'https://github.com/fmontserrat',
	},
	{
		icon: 'icons/stackoverflow.svg',
		link: 'https://stackoverflow.com/users/13397277/francesc-montserrat',
	},
];

const Footer = () => (
	<React.Fragment>
		<FooterWrapper>
			<StyledContainer>
				<Copyright>
					<FooterText>
						<div>{new Date().getFullYear()} ©</div>
						<img width={48} height={48} src="/icons/pin.svg" alt="location" />
						<div> Předboj</div>
					</FooterText>
				</Copyright>
				<SocialIcons>
					<FooterLink>
						<ExternalLink href="tel:+420608095964">+420 608 095 964</ExternalLink>
					</FooterLink>
					<FooterLink>
						<ExternalLink href="mailo:fmontserrat.carvajal@gmail.com">
							fmontserrat.carvajal@gmail.com
						</ExternalLink>
					</FooterLink>
					<div className="flex justify-center">
						{SOCIAL.map(({ icon, link }) => (
							<ExternalLink key={link} href={link}>
								<img src={icon} alt="link" />
							</ExternalLink>
						))}
					</div>
				</SocialIcons>
			</StyledContainer>
		</FooterWrapper>
	</React.Fragment>
);

const SocialIcons = styled.div`
	display: flex;

	img {
		margin: 0 8px;
		width: 24px;
		height: 24px;
	}

	@media (max-width: ${(props) => props.theme.screen.sm}) {
		margin-top: 40px;
		display: inline-block;
	}
`;

const FooterWrapper = styled.footer`
	background-color: ${(props) => props.theme.color.primary};
	padding: 32px 0;
`;

const Copyright = styled.div`
	font-family: ${(props) => props.theme.font.secondary};
	${(props) => props.theme.font_size.small};
	color: ${(props) => props.theme.color.black.regular};

	a {
		text-decoration: none;
		color: inherit;
	}
`;

const StyledContainer = styled(Container)`
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: ${(props) => props.theme.screen.sm}) {
		flex-direction: column;
		text-align: center;
	}
`;

const FooterText = styled.div`
	position: relative;
	align-items: center;
	font-family: ${(props) => props.theme.font.primary};
	${(props) => props.theme.font_size.small};
	color: ${(props) => props.theme.color.white.regular};
	display: flex;

	img {
		padding: 0 6px;
	}
`;

const FooterLink = styled.div`
	${(props) => props.theme.font_size.small};

	a {
		color: ${(props) => props.theme.color.white.regular};
		margin: 0 8px;
	}

	@media (max-width: ${(props) => props.theme.screen.sm}) {
		padding-bottom: 12px;
		margin: 0;
	}
`;

export default Footer;

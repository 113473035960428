import styled from 'styled-components';

export const Container = styled.div`
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
	padding: 0 16px;

	@media (min-width: ${(props) => props.theme.screen.xs}) {
		max-width: 540px;
	}

	@media (min-width: ${(props) => props.theme.screen.sm}) {
		max-width: 720px;
	}

	@media (min-width: ${(props) => props.theme.screen.md}) {
		max-width: 960px;
	}

	@media (min-width: ${(props) => props.theme.screen.lg}) {
		max-width: 1200px;
	}

	${(props: { fluid: boolean }) =>
		props.fluid &&
		`
    max-width: 1200px !important;
  `};
`;

export const Section = styled.section`
	padding: 64px 0;
	overflow: hidden;
	background-color: ${(props) => props.theme.color.white.regular};

	@media (max-width: ${(props) => props.theme.screen.md}) {
		padding: 48px 0;
	}

	${(props: { accent: string; theme: { color: { primary: string; white: { dark: string } } } }) =>
		props.accent &&
		`background-color: ${
			props.accent === 'secondary' ? props.theme.color.white.dark : props.theme.color.primary
		}`};
`;

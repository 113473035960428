const theme = {
	font: {
		primary: `'Montserrat', serif`,
		secondary: `'Nunito', serif`,
	},
	font_size: {
		xsmall: 'font-size: 16px; line-height: 22px',
		small: 'font-size: 20px; line-height: 30px',
		regular: 'font-size: 24px; line-height: 32px',
		large: 'font-size: 30px; line-height: 36px',
		larger: 'font-size: 36px; line-height: 48px',
		xlarge: 'font-size: 48px; line-height: 56px',
	},
	color: {
		white: {
			regular: '#FFFFFF',
			dark: '#F6F6F6',
			darker: '#E0E0E0',
		},
		black: {
			lighter: '#ABA8AF',
			light: '#564F62',
			regular: '#211E26',
		},
		primary: '#d65050',
		primaryLight: '#fdf6f6',
		secondary: '#e28484',
	},
	screen: {
		xs: '575px',
		sm: '767px',
		md: '991px',
		lg: '1199px',
	},
};

export default theme;

import { Wrapper } from './style';
import React from 'react';
import Collapsible from 'react-collapsible';

const FaqItem = ({ title, children }) => (
	<Wrapper>
		<Collapsible
			className="faq negative"
			openedClassName="faq active negative"
			triggerClassName="faq-title negative"
			triggerOpenedClassName="faq-title active negative"
			triggerTagName="button"
			contentInnerClassName="faq-content negative"
			trigger={title}
			transitionTime={300}
			easing="ease-out"
		>
			{children}
		</Collapsible>
	</Wrapper>
);

export default FaqItem;

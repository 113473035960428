import React from 'react';

import Layout from '../Layout';
import Navbar from '../Navbar';

import Header from '../../sections/Header';
import Services from '../../sections/Services';
import References from '../../sections/References';
import WorkedWith from '../../sections/WorkedWith';
import Faq from '../../sections/Faq';
import Footer from '../../sections/Footer';
import Contact from '../../sections/Contact';
import Portfolio from '../../sections/Portfolio';
import About from '../../sections/About';

const IndexPage = (props) => (
	<Layout>
		<Navbar language={props.language} />
		<Header language={props.language} />
		<Services language={props.language} />
		<Portfolio language={props.language} />
		<WorkedWith language={props.language} />
		<References language={props.language} />
		<About language={props.language} />
		<Faq language={props.language} />
		<Contact language={props.language} />
		<Footer language={props.language} />
	</Layout>
);

export default IndexPage;

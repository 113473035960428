import React from 'react';
import styled from 'styled-components';

import { Container, Section } from '../global';
import ExternalLink from '../common/ExternalLink';
import { useTranslation } from 'react-i18next';

const LOGOS = [
	{
		src: '/logos/dexguru_logo.png',
		link: 'https://dex.guru/'
	},
	{
		src: '/logos/accolade_logo.jpg',
		link: 'https://accolade.com/',
	},
	{
		src: '/logos/dap_logo.png',
		link: 'https://www.smartresponse.org/',
	},
	{
		src: '/logos/jp_logo.png',
		link: 'https://www.jpmorganchase.com/',
	},
	{
		src: '/logos/barclays_logo.svg',
		link: 'https://home.barclays/',
	},
	{
		src: '/logos/abiquo_logo.png',
		link: 'https://abiquo.com/',
	},
];

const WorkedWith = () => {
	const { t } = useTranslation('workedWith');

	return (
		<Section id="worked with">
			<StyledContainer>
				<div className="pb-12 md:pb-16">
					<div className="relative">
						<div className="absolute inset-0 flex items-center" aria-hidden="true">
							<div className="w-full border-t border-gray-300" />
						</div>
						<div className="relative flex justify-center">
							<h2 className="px-4 bg-white">Past Employers and Clients</h2>
						</div>
					</div>
				</div>
				<LogoGrid>
					{LOGOS.map(({ src, link }) => (
						<ExternalLink key={link} href={link}>
							<img width="200px" src={src} alt={src} />
						</ExternalLink>
					))}
				</LogoGrid>
				<Disclaimer>
					*All product and company names are trademarks™ or registered® trademarks of their
					respective holders which are to identify past or present employment, partnership,
					internship or volunteering relationships. Use of them does not imply any affiliation with
					or endorsement by them.
				</Disclaimer>
			</StyledContainer>
		</Section>
	);
};

const LogoGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 64px;
	justify-items: center;
	align-items: center;

	a {
		svg {
			width: 100%;
		}
	}

	@media (max-width: ${(props) => props.theme.screen.sm}) {
		grid-template-columns: 1fr;
		justify-items: center;
		grid-gap: 48px;
	}
`;

const StyledContainer = styled(Container)`
	display: flex;
	position: relative;
	flex-direction: column;

	@media (max-width: ${(props) => props.theme.screen.md}) {
		justify-content: center;
	}
`;

const Disclaimer = styled.em`
	font-size: 13px;
	font-style: italic;
	margin: 48px;
	text-align: justify;

	@media (max-width: ${(props) => props.theme.screen.md}) {
		margin: 48px 12px 0 12px;
		fonts-size: 11px;
	}
`;
export default WorkedWith;

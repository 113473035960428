import { Container, Section } from '../global';
import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';

const Contact = () => (
	<StaticQuery
		query={graphql`
			query {
				art_face: file(sourceInstanceName: { eq: "art" }, name: { eq: "face" }) {
					childImageSharp {
						fluid(maxWidth: 1000) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
			}
		`}
		render={() => (
			<Section id="contact">
				<Container>
					<div className="pt-8 pb-16 md:pb-20">
						<div className="relative">
							<div className="absolute inset-0 flex items-center" aria-hidden="true">
								<div className="w-full border-t border-gray-300" />
							</div>
							<div className="relative flex justify-center">
								<h2 className="px-4 bg-white">Contact</h2>
							</div>
						</div>
					</div>
					<FormContainer>
						<Form
							method="post"
							target="_blank"
							netlify-honeypot="bot-field"
							data-netlify="true"
							name="contact"
							action="https://getform.io/f/9603f20f-b324-49d5-a316-8f4ecc955a58"
						>
							<p>Hi there!</p>
							<br />
							<p>Type any question below, I&apos;ll answer within the next 2 business days.</p>
							<input
								className="h-7 mt-4 border p-5 rounded-md"
								required={true}
								placeholder=" Your Name"
								type="text"
								name="name"
							/>
							<input
								className="h-7 mt-4 border p-5 rounded-md"
								required={true}
								placeholder=" email@domain.com"
								type="email"
								name="email"
							/>
							<textarea
								required={true}
								className="h-80 mt-4 border p-5 rounded-md"
								placeholder=" Enter your questions here."
								name="message"
							/>
							<Send type="submit">
								<span>Ask Me!</span>
								<svg
									className="ml-3 -mr-1 h-5 w-5"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
									<path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
								</svg>
							</Send>
						</Form>
					</FormContainer>
				</Container>
			</Section>
		)}
	/>
);

const FormContainer = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
	width: 100%;
	padding: 0 16px;
`;

const Form = styled.form`
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 100%;
`;

const Send = styled.button`
	background-color: ${(props) => props.theme.color.secondary};
	color: ${(props) => props.theme.color.white.regular};
	margin-top: 48px;
	padding: 20px;
	border-radius: 8px;
	min-width: 100px;
	max-width: 200px;
	display: flex;
	justify-content: center;

	@media (max-width: ${(props) => props.theme.screen.sm}) {
		margin: 16px auto 0 auto;
	}
`;

export default Contact;

import { Section, Container } from '../global';
import React from 'react';

import FaqItem from '@common/FaqItem';

const FAQS = [
	{
		title: 'How much will it cost me?',
		content: () => (
			<>
				<p>
					The cost is based on the complexity, size and length and of the project. The preliminary call to discuss your requirements
					and detailed project proposal are free of charge.
				</p>
			</>
		),
	},
	{
		title: 'Who are your clients?',
		content: () => (
			<p>People with passion for their business, a positive mindset and a focus on quality.</p>
		),
	},
	{
		title: 'Are you available now?',
		content: () => (
			<>I am available to take new projects now but cannot start them until May 2021.</>
		),
	},
	{
		title: 'Are you interested on a full-time role?',
		content: () => (
			<>
				Sorry but no. That would leave me no time for my current clients. Part-time roles, projects
				or hourly cooperation only.
			</>
		),
	},
	{
		title: 'Why should I choose you?',
		content: () => (
			<>
				<p>
					I will listen to your situation and propose solutions based on my experience and agree on
					the best option for you, no silver bullets. Then I will implement that solution and keep
					you up to date with the level of detail you are most comfortable with: from a daily report
					for perfectionists to the strictly necessary for busy business owners.
				</p>
				<br />
				<p>
					I will match your passion for your business, root for it and support it with my own
					passion for quality software and tailored solutions.
				</p>
			</>
		),
	},
];

const Faq = () => (
	<Section id="faq" accent>
		<Container>
			<div className="pb-16 md:pb-20">
				<div className="relative">
					<div className="absolute inset-0 flex items-center" aria-hidden="true">
						<div className="w-full border-t border-gray-300" />
					</div>
					<div className="relative flex justify-center">
						<h2 style={{ backgroundColor: '#d65050' }} className="px-4 text-white">
							Frequently Asked Questions
						</h2>
					</div>
				</div>
			</div>
			<div>
				{FAQS.map(({ title, content }) => (
					<FaqItem title={title} key={title}>
						{content()}
					</FaqItem>
				))}
			</div>
		</Container>
	</Section>
);

export default Faq;

import { Container, Section } from '../global';
import PortfolioCard from '../common/PortfolioCard';
import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import _ from 'lodash';
import styled from 'styled-components';

// NOTE: Image mockups made with https://techsini.com/multi-mockup/ or https://www.screely.com/
const portfolioData = [
	{
		name: 'Plancto',
		picture: 'plancto',
		url: 'https://github.com/fmontserrat/subsurface-map',
		technologies: 'Typescript, React, Firebase Functions, Firebase Datastore',
		features: '<ul>' +
			'<li>One click SSO using Facebook</li>' +
			'<li>Dynamic Google Maps interaction</li>' +
			'<li>Third party API integrations</li>' +
			'<li>Social capabilities</li></ul>',
		description:
			'<p>Plancto allows scuba divers to log their dives in the cloud manually or import them from Subsurface. Diver profiles can be shared via link or embedded into blogs. A diver can also search for dive sites and dive centers worldwide and monitor their air consumption overtime.</p><br/><p><b>Note that the original site is decommissioned. An open source self serviced version of the tool is available on my Github account.</b></p>',
		role: 'Product manager, designer and developer',
	},
	{
		name: 'Smart Response',
		picture: 'smartresponse',
		url: 'https://www.smartresponse.org',
		technologies: 'Angular, Java, Spring, Hibernate, MySQL',
		features: '<ul><li>Classic three-tier architecture</li>' +
			'<li>MVC, REST API, ORM and relational database</li>' +
			'<li>Easy to use, simple design and architecture, fast to load</li>' +
			'<li>Supports 6 languages</li>' +
			'</ul>',
		description:
			'<p>Smart Response is built and maintained exclusively by volunteers. Its main technological requirements are simplicity, maximization of volunteer time usage, fast load time in disaster locations and language internationalization that covers the languages of those in need.</p>',
		role:
			'Volunteer senior engineer, consultant, mentor',
	},
	{
		name: 'Tenstars',
		picture: 'tenstars',
		url: 'https://www.tenstars.co',
		technologies: 'Javascript, React, Firebase Functions, Firebase Realtime Database',
		features:
			'<ul><li>Tight Stripe Integration</li>' +
			'<li>Offers a suite of no-code embeddable widgets</li>' +
			'<li>Realtime notifications</li>' +
			'<li>Colorful data analytics visualizations</li>' +
			'</ul>',
		description:
			'<p>Startup focused on improving the revenue of SaaS products by increasing their client retention. Tentars intercepts cancellations on the customer&apos;s behalf and offers incentives after surveying cancellation reasons. All data is offered in visual analytics.</p>',
		role: 'Co-Founder and CTO',
	},
	{
		name: 'Iveta Oršulíková',
		picture: 'ivetacz',
		url: 'https://ivetaorsulikova.cz',
		technologies: 'Gatsby, Typescript',
		features: '<ul><li>References from satisfied clients</li>' +
			'<li>Media section with articles and videos</li>' +
			'<li>Fast load time and image compression</li>' +
			'<li>Responsive design</li></ul>',
		description:
			'<p>A visual and effective professional site that centralised the freelance&apos;s details. The site has doubled the client outreach compared to LinkedIn alone and reduced the number of direct questions.</p>',
		role: 'Freelance developer and designer',
	},
];

const Portfolio = () => (
	<StaticQuery
		query={graphql`
			query {
				smartresponse: file(sourceInstanceName: { eq: "art" }, name: { eq: "smartresponse" }) {
					childImageSharp {
						fluid(maxWidth: 760) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}

				plancto: file(sourceInstanceName: { eq: "art" }, name: { eq: "plancto" }) {
					childImageSharp {
						fluid(maxWidth: 760) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}

				tenstars: file(sourceInstanceName: { eq: "art" }, name: { eq: "tenstars" }) {
					childImageSharp {
						fluid(maxWidth: 760) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}

				ivetacz: file(sourceInstanceName: { eq: "art" }, name: { eq: "ivetacz" }) {
					childImageSharp {
						fluid(maxWidth: 760) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
			}
		`}
		render={(data) => (
			<StyledSection id="portfolio">
				<Container>
					<div className="pb-16 md:pb-20">
						<div className="relative">
							<div className="absolute inset-0 flex items-center" aria-hidden="true">
								<div className="w-full border-t border-gray-300" />
							</div>
							<div className="relative flex justify-center">
								<h2 className="px-4 bg-white">Projects</h2>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-16 lg:gap-20">
						{portfolioData.map((p) => (
							<PortfolioCard
								key={p.name}
								name={p.name}
								picture={_.get(data, `${p.picture}.childImageSharp.fluid`)}
								url={p.url}
								description={p.description}
								role={p.role}
								features={p.features}
								technologies={p.technologies}
							/>
						))}
					</div>
				</Container>
			</StyledSection>
		)}
	/>
);

export const StyledSection = styled(Section)`
	padding: 64px 0 32px 0;
`;

export default Portfolio;

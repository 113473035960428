import styled from 'styled-components';

export const Wrapper = styled.div`
	&:hover {
		cursor: pointer;
	}

	.faq {
		border-bottom: 2px solid ${(props) => props.theme.color.black.lighter};

		&.tech {
			border: none;
			border-radius: 4px;
			background-color: ${(props) => props.theme.color.white.dark};
		}
	}

	.faq-title {
		border: none;
		background: none;
		padding: 0;
		outline: none;
		width: 100%;
		text-align: left;
		cursor: pointer;
		color: ${(props) => props.theme.color.black.light};

		&.negative {
			color: ${(props) => props.theme.color.white.regular};
		}

		&.tech {
			padding-left: 32px;
		}

		font-family: ${(props) => props.theme.font.secondary};
		${(props) => props.theme.font_size.regular};
		font-weight: bold;
		padding: 24px;
		padding-right: 72px;
		padding-left: 16px;
		position: relative;

		&::after {
			content: '';
			display: block;
			width: 10px;
			height: 10px;
			border-left: 2px solid ${(props) => props.theme.color.primary};
			border-bottom: 2px solid ${(props) => props.theme.color.primary};

			position: absolute;
			top: 34px;
			right: 36px;
			transform: rotate(-45deg);
			transition: transform 0.3s ease-in-out;
		}

		&.negative {
			&::after {
				border-left: 2px solid ${(props) => props.theme.color.white.regular};
				border-bottom: 2px solid ${(props) => props.theme.color.white.regular};
			}
		}

		&.active {
			&::after {
				transform: rotate(135deg);
			}

			&.tech {
				padding-left: 32px;
			}
		}
	}

	.faq-content {
		padding: 0 72px 32px 16px;
		line-height: 26px;
		${(props) => props.theme.font_size.small};
		color: ${(props) => props.theme.color.white.darker};

		&.tech {
			padding: 32px;
		}

		&.negative {
			color: ${(props) => props.theme.color.white.darker};

			p {
				color: ${(props) => props.theme.color.white.darker};
				${(props) => props.theme.font_size.small};
			}
		}
	}
`;

import ExternalLink from './ExternalLink';
import React from 'react';
import Img, { FluidObject } from 'gatsby-image';
import styled from 'styled-components';

type PortfolioCardProps = {
	picture: FluidObject | FluidObject[];
	url: string;
	name: string;
	description: string;
	role: string;
	features: string;
	technologies: string;
};

const PortfolioCard = (props: PortfolioCardProps) => (
		<div className="h-auto border-1 rounded-lg rounded w-auto relative h-auto">
			<Content>
				<div className="border-8 rounded mb-6 mx-3 md:mx-0">
					<ExternalLink href={props.url} target="_blank" rel="noopener noreferrer">
						<Img fluid={props.picture} />
					</ExternalLink>
				</div>
				<ExternalLink href={props.url} target="_blank" rel="noopener noreferrer">
					<h3 className="text-center mb-8">{props.name}</h3>
				</ExternalLink>
				<div className="px-2">
					<p className="mb-2 font-bold">Role</p>
					<p className="mb-10">{props.role}</p>

					<p className="mb-2 font-bold">Description</p>
					<div className="mb-10" dangerouslySetInnerHTML={{ __html: props.description }} />

					<p className="mb-2 font-bold">Technologies</p>
					<p className="mb-10">{props.technologies}</p>

					<p className="mb-2 font-bold">Highlights</p>
					<div dangerouslySetInnerHTML={{ __html: props.features }} />
				</div>
			</Content>
		</div>
);

const Content = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	p,
	ul,
	li {
		${(props) => props.theme.font_size.xsmall};
	}

	ul {
		padding: 0;
		margin-top: 16px;
	}

	li {
		margin: 12px;
	}

	li:before {
		content: '\\2713';
		color: lightgreen;
		padding-left: 12px;
		margin-right: 12px;
	}

	@media (max-width: ${(props) => props.theme.screen.md}) {
		flex-direction: column;
	}
`;

export default PortfolioCard;

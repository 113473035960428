import React from 'react';
import styled from 'styled-components';

import { Container, Section } from '@components/global';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const Services = () => {
	const { t } = useTranslation('services');

	return (
		<Section id="services">
			<Container>
				<div className="grid grid-cols-1 lg:grid-cols-3">
					<div className="mt-24 lg:mt-6 text-center flex justify-content-center flex-col lg:border-r-2 lg:mr-2">
						<Art>
							<img width="128px" src="/icons/web.svg" alt="web" />
						</Art>
						<div className="mt-8">
							<h3>{t('title1')}</h3>
							<p className="mt-8 mx-4 leading-10">
								Tailored dynamic sites adapted to all devices. Designed and documented with handout
								in mind.
							</p>
						</div>
					</div>
					<div className="mt-24 lg:mt-6 text-center flex justify-content-center flex-col lg:mx-2">
						<Art>
							<img width="128px" src="/icons/cloud.svg" alt="scalable" />
						</Art>
						<div className="mt-8">
							<h3>{t('title2')}</h3>
							<p className="mt-8 mx-4 leading-10">
								Solution ideation, design and implementation. Bootstrapping of ideas that scale with
								the business.
							</p>
						</div>
					</div>
					<div className="mt-24 lg:mt-6 text-center flex justify-content-center flex-col lg:border-l-2 lg:ml-2">
						<Art>
							<img width="128px" src="/icons/hands.svg" alt="teamwork" />
						</Art>
						<div className="mt-8">
							<h3>Team Player for Hire</h3>
							<p className="mt-8 mx-4 leading-10">
								Fullstack Developer addition to any team during critical times to help meet that
								deadline.
							</p>
						</div>
					</div>
				</div>
				<div className="mt-20 md:mt-40">
					<div className="relative">
						<div className="absolute inset-0 flex items-center" aria-hidden="true">
							<div className="w-full border-t border-gray-300" />
						</div>
						<div className="relative flex justify-center">
							<h2 className="px-4 bg-white">Technical Skills</h2>
						</div>
					</div>
					<div className="mt-12">
						<div className="mt-10 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
							<div className="px-2 rounded-lg h-auto overflow-auto lg:mr-4 bg-white pb-3">
								<h3 className="text-center my-6 text-black">Front End</h3>
								<a
									data-theme="light"
									data-layers="1, 2,3,4"
									data-stack-embed="true"
									href="https://embed.stackshare.io/stacks/embed/dc8c46d4fd0022cc20fce9ad7749e5"
								/>
							</div>
							<div className="px-2 rounded-lg my-10 lg:my-0 h-auto overflow-auto lg:mx-3 bg-white pb-3">
								<h3 className="text-center my-6 text-black">Back End</h3>
								<a
									data-theme="light"
									data-layers="1,2,3,4"
									data-stack-embed="true"
									href="https://embed.stackshare.io/stacks/embed/1c35707066c47d7f17a95412614fb9"
								/>
							</div>
							<div className="px-2 rounded-lg h-auto overflow-auto lg:ml-4 bg-white pb-3">
								<h3 className="text-center my-6 text-black">Databases</h3>
								<a
									data-theme="light"
									data-layers="1,2,3,4"
									data-stack-embed="true"
									href="https://embed.stackshare.io/stacks/embed/751ebf46178f9a094a92739736fea3"
								/>
							</div>
						</div>
						<div className="px-8 rounded-lg overflow-auto mt-10 bg-white pb-2 invisible md:visible h-0 md:h-auto">
							<h3 className="text-center my-6 text-black">Cloud</h3>
							<a
								data-theme="light"
								data-layers="1,2,3,4"
								data-stack-embed="true"
								href="https://embed.stackshare.io/stacks/embed/76af979ce3fdc55c307639f2445962"
							/>
						</div>
					</div>
				</div>
				<Helmet>
					<script
						async
						src="https://cdn1.stackshare.io/javascripts/client-code.js"
						charset="utf-8"
					></script>
				</Helmet>
			</Container>
		</Section>
	);
};

const Art = styled.figure`
	width: 96px;
	margin: 0 auto;
	padding-bottom: 16px;
`;

export default Services;

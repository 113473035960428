import styled from 'styled-components';

import { Container } from '@components/global';

export const Nav = styled.nav`
	background-color: ${(props) => props.theme.color.primary};
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 1000;
	position: sticky;

	&.nav-active {
		background-color: black;
	}
`;

export const StyledContainer = styled(Container)`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const NavListWrapper = styled.div`
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: row;

		${({ mobile }) =>
			mobile &&
			`
        flex-direction: column;
        margin-top: 1em;

        > ${NavItem} {
          margin: 0;
          margin-top: 0.75em;
        }
      `};
	}
`;

export const NavItem = styled.li`
	margin: 0 0.75em;
	font-family: ${(props) => props.theme.font.secondary};
	${(props) => props.theme.font_size.small};

	a {
		text-decoration: none;
		opacity: 0.7;
		color: ${(props) => props.theme.color.white.regular};

		@media (max-width: ${(props) => props.theme.screen.md}) {
			color: ${(props) => props.theme.color.black.regular};
		}
	}

	&:hover {
		opacity: 0.8;
	}

	&.active {
		a {
			opacity: 1;
		}
	}
`;

export const MobileMenu = styled.div`
	width: 100%;
	padding-bottom: 12px;
	text-align: left;
	background-color: white;
`;

export const Brand = styled.div`
	font-family: ${(props) => props.theme.font.primary};
	${(props) => props.theme.font_size.large};
	color: ${(props) => props.theme.color.white.regular};
	font-weight: 600;
	padding: 16px 0;
`;

export const Mobile = styled.div`
	display: none;

	@media (max-width: ${(props) => props.theme.screen.md}) {
		display: block;
	}

	${(props) =>
		props.hide &&
		`
    display: block;

    @media (max-width: ${props.theme.screen.md}) {
      display: none;
    }
  `}
`;

import React from 'react';
import './ReferenceCard.css';
import styled from 'styled-components';
import ExternalLink from './ExternalLink';

type ReferenceCardProps = {
	text: string[];
	author: string;
	companies: string[];
	companyLinks: string[];
	companyLogos: string[];
	description?: string;
	picture?: string;
	position: string;
	linkedInLink: string;
};

export default (props: ReferenceCardProps) => (
	<Card>
		<div className="py-4 px-4 sm:px-6 md:flex md:flex-col">
			<ExternalLink href={props.companyLinks[0]} className="md:flex-shrink-0 relative">
				<img className="py-3 h-16" src={props.companyLogos[0]} alt="company" />
			</ExternalLink>
			<blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
				<div className="relative text-lg font-medium text-white md:flex-grow">
					<svg
						className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-red-700"
						fill="currentColor"
						viewBox="0 0 32 32"
						aria-hidden="true"
					>
						<path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
					</svg>
					<p className="relative text-white text-lg">{props.text}</p>
				</div>
				<footer className="mt-8">
					<div className="flex">
						<div className="flex-grow-0 flex-shrink-0">
							<ExternalLink
								href={props.linkedInLink}
								className="inline-flex rounded-full border-2 border-white"
							>
								<img className="h-12 w-12 rounded-full" src={props.picture} alt={props.author} />
							</ExternalLink>
						</div>
						<div className="ml-4">
							<div className="text-base font-medium text-white">{props.author}</div>
							<div className="text-base font-medium text-gray-300">
								{props.position}, {props.companies[0]}
							</div>
						</div>
					</div>
				</footer>
			</blockquote>
		</div>
	</Card>
);

const Card = styled.div`
	position: relative;
	background-color: ${(props) => props.theme.color.primary};
	min-height: 200px;
	width: 520px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 20px;
	margin: 32px;
	border-radius: 2%;

	@media (max-width: ${(props) => props.theme.screen.md}) {
		margin: 16px;
	}
`;

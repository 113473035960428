import React from 'react';
import styled from 'styled-components';

import { Section, Container } from '@components/global';

const About = () => (
	<Section id="about me">
		<Container>
			<div>
				<div className="pb-16 md:pb-20">
					<div className="relative">
						<div className="absolute inset-0 flex items-center" aria-hidden="true">
							<div className="w-full border-t border-gray-300" />
						</div>
						<div className="relative flex justify-center">
							<h2 className="px-4 bg-white">About Me</h2>
						</div>
					</div>
				</div>
			</div>
			<Grid>
				<div>
					<h3>I build and document software with change in mind</h3>
					<br />
					<br />
					<p>
						My experience has taught me that software needs to fit the size and stage of each
						individual organization. Software solutions need be cost-effective and iterate very
						quickly in early phases of a business; and urgently become high performing and scalable
						platforms to match growth.
					</p>
					<br />
					<br />
					<p>
						As a business evolves and adapts so does its software. I am prepared to deliver
						solutions to current needs and long term strategies. That is why I always document
						handouts with future plans and contingencies in mind.
					</p>
					<br />
					<br />
				</div>
				<Art>
					<img src="/ilustrations/tailor.svg" alt="tailoring" />
				</Art>
			</Grid>
			<Grid inverse>
				<Art>
					<img src="/ilustrations/impact.svg" alt="impact" />
				</Art>
				<div>
					<h3>Big impact in complex industries</h3>
					<br />
					<p>
						I graduated with a masters degree in Computer Engineering and have been building
						Software professionally for the past 12 years. From freelance websites and SaaS
						prototypes to low latency scalable platforms for the finance and healthcare industries.
					</p>
					<br />
					<br />
					<p>
						I am a Principal Engineer at Accolade, leading their member web team. The product is
						used by about 100 customers and more than 2 million members across commercial and
						government organizations.
					</p>
					<br />
					<br />
					<p>
						Previous roles include being part of the development of Barclays&apos; Equities Test
						Automation Framework and JP Morgan Chase&apos;s Custody Platform. According to their
						2020 reports, JPMC custodes over 28 trillion dollars in assets.
					</p>
				</div>
			</Grid>
		</Container>
	</Section>
);

const Grid = styled.div`
	display: grid;
	grid-template-columns: 3fr 2fr;
	grid-gap: 40px;
	text-align: left;
	align-items: center;
	justify-items: center;
	padding-top: 50px;

	${(props) =>
		props.inverse &&
		`
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

	h2 {
		margin-bottom: 16px;
	}

	@media (max-width: ${(props) => props.theme.screen.md}) {
		grid-template-columns: 1fr;
		text-align: left;
		grid-gap: 8px;
		margin: 16px;

		&:last-child {
			margin-bottom: 24px;
		}

		${(props) =>
			props.inverse &&
			`
        ${Art} {
          order: 2;
          padding: 16px;
        }
    `}
	}
`;

const Art = styled.figure`
	margin: 0 12px;
	max-width: 400px;
	width: 100%;
`;

export default About;

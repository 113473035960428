import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import styled from 'styled-components';
import { Container } from '../../global';
import { Brand, Mobile, MobileMenu, Nav, NavItem, NavListWrapper, StyledContainer } from './style';
import { withTranslation } from 'react-i18next';

const NAV_ITEMS = ['Services', 'Portfolio', 'References', 'About Me', 'FAQ', 'Contact'];

class Navbar extends React.Component {
	state = {
		mobileMenuOpen: false,
	};

	componentDidMount() {
		const navbar = document.querySelector('nav');
		window.onscroll = () => {
			if (window.scrollY > 550) {
				navbar.classList.add('nav-active');
			} else {
				navbar.classList.remove('nav-active');
			}
		};
	}

	toggleMobileMenu = () => {
		this.setState((prevState) => ({ mobileMenuOpen: !prevState.mobileMenuOpen }));
	};

	closeMobileMenu = () => {
		if (this.state.mobileMenuOpen) {
			this.setState({ mobileMenuOpen: false });
		}
	};

	getNavAnchorLink = (item) => (
		<AnchorLink href={`#${item.toLowerCase()}`} onClick={this.closeMobileMenu}>
			{this.props.t(item.toLowerCase())}
		</AnchorLink>
	);

	getNavList = ({ mobile = false }) => (
		<NavListWrapper mobile={mobile}>
			<Scrollspy
				items={NAV_ITEMS.map((item) => item.toLowerCase())}
				currentClassName="active"
				mobile={mobile}
				offset={-64}
			>
				{NAV_ITEMS.map((navItem) => (
					<NavItem key={navItem}>{this.getNavAnchorLink(navItem)}</NavItem>
				))}
				{/*{!mobile && <LanguagePicker language={this.props.language}/>}*/}
			</Scrollspy>
		</NavListWrapper>
	);

	render() {
		const { mobileMenuOpen } = this.state;

		return (
			<Nav className={'nav' + mobileMenuOpen ? ' is-open' : ''} {...this.props}>
				<StyledContainer>
					<Brand>Francesc Montserrat</Brand>
					<Mobile>
						<MenuContainer>
							<button onClick={this.toggleMobileMenu} style={{ color: 'black' }}>
								<img src="/icons/menu.svg" alt="menu" />
							</button>
							{/*TODO later <LanguagePicker language={this.props.language}/>*/}
						</MenuContainer>
					</Mobile>

					<Mobile hide>{this.getNavList({})}</Mobile>
				</StyledContainer>
				<Mobile>
					{mobileMenuOpen && (
						<MobileMenu>
							<Container>{this.getNavList({ mobile: true })}</Container>
						</MobileMenu>
					)}
				</Mobile>
			</Nav>
		);
	}
}

const MenuContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

export default withTranslation('header')(Navbar);
